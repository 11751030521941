import React, { useEffect, useState } from "react"
import { breakpointsValues } from "../assets/media"
import { isDomAvailable } from "./isDomAvailable"

export const useDetectDevice = () => {
    if (!isDomAvailable()) {
        return false
    }
    const [width, setWidth] = useState<number>(window.innerWidth)
    function handleWindowSizeChange() {
        setWidth(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange)
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange)
        }
    }, [])
    let isMobile: boolean = width <= breakpointsValues.md
    return isMobile
}
