import React from 'react'
import { Container, ContainerMobile, InvisibleCell, StyledCell } from './TableDayHours.style'
import { css } from "@emotion/react"
import { useRef } from 'react'
import slugify from 'slugify'
import theme from "./../../../../gatsby-plugin-theme-ui"
import { Slot } from '../../../../entity/slot'
import { Cell, getColorBySegment, tabValueHeure, tabValueJour } from '../../../atoms/Cell'
import { SegmentEnum } from '../../selectionDateJour/selectionDateJour.container'
import { Box } from 'theme-ui'
import { useDetectDevice } from '../../../../hook/useDetectDevice'

interface props {
    values: Slot[],
    addOrRemoveValue: (value: Slot, remove: boolean) => void
    segment: SegmentEnum
}

export const TableDayHoursView = ({ values, addOrRemoveValue, segment }: props) => {
    const bg = theme?.gradients?.lightgray
    const isMobile = useDetectDevice()
    if(isMobile)
        return (
            <Box sx={{padding: "0em", borderRadius: "15px", width: "100%" }}>
            <ContainerMobile>
                <AbsciseMobile segment={segment} values={values} />
                <OrdonneeMobile segment={segment} values={values} />
                <InvisibleCell></InvisibleCell>
                {
                    tabValueJour.map((value, index) =>
                        <BuildLineMobile segment={segment} addOrRemoveValue={addOrRemoveValue} jour={value} key={"line" + value + index} />
                    )
                }
            </ContainerMobile>
        </Box>
        )
    else
        return (
            <Box sx={{ ...bg, padding: "2em", borderRadius: "15px", maxWidth: "1100px", width: "100%" }}>
                <Container>
                    <Abscise segment={segment} values={values} />
                    <Ordonnee segment={segment} values={values} />
                    <InvisibleCell></InvisibleCell>
                    {
                        tabValueHeure.map((value, index) =>
                            <BuildLine segment={segment} addOrRemoveValue={addOrRemoveValue} heure={value} key={"line" + value + index} />
                        )
                    }
                </Container>
            </Box>)
}

const Abscise = ({ values, segment }) => {
    // todo mettre horaire
    return (<>{
        tabValueJour.map((value, index) =>
            <TitleCell values={values} value={value} propName={"jour"} segment={segment}
                gridColumn={2 + index} gridRow={1} key={"abscise" + value + index}></TitleCell>
        )
    }
    </>)
}
const AbsciseMobile = ({ values, segment }) => {

    return (<>{
        tabValueHeure.map((value, index) =>
            <TitleCellMobile values={values} value={value} propName={"heure"} segment={segment}
                gridColumn={2 + index} gridRow={1} key={"abscise" + value + index}></TitleCellMobile>
        )
    }
    </>)
}
const Ordonnee = ({ values, segment }) => {

    return (<>{
        tabValueHeure.map((value, index) =>
            <TitleCell values={values} value={value} propName={"heure"} segment={segment}
                gridColumn={1} gridRow={2 + index} key={"ordonnee" + value + index}></TitleCell>
        )
    }
    </>)
}

const OrdonneeMobile = ({ values, segment }) => {
    // todo mettre horaire
    return (<>{
        tabValueJour.map((value, index) =>
            <TitleCellMobile values={values} value={value} propName={"jour"} segment={segment}
                gridColumn={1} gridRow={2 + index} key={"ordonnee" + value + index}></TitleCellMobile>
        )
    }
    </>)
}





const BuildLine = ({ heure, addOrRemoveValue, segment }) => {

    return (
        <>
            {tabValueJour.map((item, index) => {
                const ref = useRef("Cell" + item + index)
                return (
                    <Cell innerRef={ref} segment={segment}
                        value={{ "heure": heure, "jour": item }}
                        addOrRemoveValue={addOrRemoveValue}
                        key={"Cell" + item + index} />)
            }
            )}
        </>
    )
}

const BuildLineMobile = ({ jour, addOrRemoveValue, segment }) => {

    return (
        <>
            {tabValueHeure.map((item, index) => {
                const ref = useRef("Cell" + item + index)
                return (
                    <Cell innerRef={ref} segment={segment}
                        value={{ "jour": jour, "heure": item }}
                        addOrRemoveValue={addOrRemoveValue}
                        key={"Cell" + item + index} />)
            }
            )}
        </>
    )
}

interface propsTitleCell {
    segment: SegmentEnum, values: Slot[], value: string, propName: "jour" | "heure", gridColumn: number, gridRow: number
}

const TitleCell = ({ segment, values, value, propName, gridColumn, gridRow }: propsTitleCell) => {


    const result = isValuePresent(values, propName, value)
    return (<StyledCell css={css`
    border-radius:10px;
    min-height:2em;
    padding:0 4px;
    grid-column:${gridColumn};
    grid-row:${gridRow};
    background-color: ${result ? getColorBySegment(segment) : ""};
    color: ${theme.colors.text};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `}> {value}</StyledCell >)

}

interface propsTitleCellMobile {
    segment: SegmentEnum, values: Slot[], value: string, propName: "heure" | "jour", gridColumn: number, gridRow: number
}

const TitleCellMobile = ({ segment, values, value, propName, gridColumn, gridRow }: propsTitleCellMobile) => {

    return (<StyledCell css={css`
        min-height:2em;
        padding:0 4px;
        grid-column:${gridColumn};
        grid-row:${gridRow};
        background-color: ${getColorBySegment(segment)};
        color: ${theme.colors.text};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        box-shadow: none;
        font-size: 14px;
        `}> {value}</StyledCell >)

}

export const isValuePresent = (values: Slot[] = [], propName: "jour" | "heure", valueItem: string) => {
    if (!values || values.length <= 0) return false

    const result: boolean = values.findIndex((item: Slot) => item[propName] == slugify(valueItem, { lower: true })) > -1
    return result
}


