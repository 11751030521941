import *  as React from 'react'
import { useEffect, useState } from 'react'
import slugify from 'slugify'
import { TableDayHours } from '../TableDayHours/tableDayHoursSegment'
import { useDispatch, useSelector } from "react-redux"
import { Slot } from '../../../entity/slot'
import { updateSlot } from '../../../state/contactFormState/contactFormState'
import { SegmentEnum } from './selectionDateJour.container'

export const SelectionDateJourView = ({ segment }: { segment: SegmentEnum }) => {
    const contactForm = useSelector((state) => {
        return state.contactForm
    })
    const currentSlots = contactForm.slots.filter(x => x.segment == segment) || []

    const [getValues, setValues] = useState(currentSlots)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateSlot(getValues, segment))
    }, [getValues])

    const addOrRemoveValue = (value: Slot, remove: boolean) => {
        if (remove) {
            const result = getValues.filter(x =>
                (x.heure != slugify(value.heure, { lower: true })) || (x.jour != slugify(value.jour, { lower: true }))
            )
            setValues([...result])
        }
        else {
            const tab = [...new Set([...getValues, { segment: segment, jour: slugify(value.jour, { lower: true }), heure: slugify(value.heure, { lower: true }) }])] as Slot[]
            setValues(tab)
        }
    }

    return (
            <TableDayHours values={getValues} segment={segment} addOrRemoveValue={addOrRemoveValue}></TableDayHours>
    )
}





