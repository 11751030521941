import * as React from 'react';
import { SelectionDateJourView } from "./SelectionDateJour.view"


export enum SegmentEnum {
    Aide = "AIDE",
    Menage = "MENAGE",
    Enfant = "ENFANT",
    Restauration = "RESTAURATION",
    RestaurationGas = "RESTAURATIONGASTRO"
}

export const SelectionDateJourContainer = ({ segment }: { segment: SegmentEnum }) => {
    return <SelectionDateJourView segment={segment}></SelectionDateJourView>
}