const couterReducer = (state, action) => {
    
    switch (action.type) {
        case "INCREMENT":
            return Object.assign({}, state, {
                count: state.count + 1,
            })
        default:
            return initialState
    }


}

const initialState = { count: 0 }

export default couterReducer