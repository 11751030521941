/** @jsx jsx */
import { jsx, css } from '@emotion/react'

import theme from '../gatsby-plugin-theme-ui'

export const breakpointsValues = {
    xs: Number(theme.breakpoints[0].replace("px", "")),
    sm: Number(theme.breakpoints[1].replace("px", "")),
    md: Number(theme.breakpoints[2].replace("px", "")),
    lg: Number(theme.breakpoints[3].replace("px", "")),
}

export const breakpoints: number[] = [...theme.breakpoints]

export const mq = breakpoints.map(
    (bp: number) => `@media only screen and (max-width: ${bp})`
)


export const sxMedia = (breakpoint: number, values: {}) => ( {[`@media screen and (max-width: ${breakpoints[breakpoint]})`]: {...values} })