

import { css } from "@emotion/react"
import styled from "@emotion/styled";


export const Wrapper = styled.div`

`
export const Container = styled.div`
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr; 
    background-color:transparent;
    text-align: center;
    gap: 5%  1%;
    margin-bottom:2em;
`
export const ContainerMobile = styled.div`
    display: grid;
    grid-template-columns: auto repeat(4, 1fr); 
    grid-template-rows: repeat(6, 1fr);
    background-color:transparent;
    text-align: center;
    gap: 3% 2%;
    margin-bottom:5em;
`

export const InvisibleCell = styled.div`
background-color:transparent;

`
export const StyledCell = styled.div`
background-color:white;
box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
`
export const StyledCellMobile = styled.div`
background-color:white;
box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
border-radius: 5px;
`

export const Header = styled.div`
     grid-area: header;
     display:inline-flex;
     justify-content: space-between;
`;

export const Sidebar = styled.div`
    grid-area: sidebar;
`;
export const Main = styled.div`
    grid-area: main;
`;

export const Footer = styled.div`
    grid-area: footer;
`;

