import { SegmentEnum } from "../../components/organisms/selectionDateJour/selectionDateJour.container"
import { Slot } from "../../entity/slot"

export const contactFormReducer = (state, action) => {

    switch (action.type) {
        case "UPDATE_SLOT_AIDE":
            state.slots = state.slots.filter(x => x.segment != SegmentEnum.Aide)
            return Object.assign({}, state, {
                slots: [...state.slots, ...action.slots],
            })
        case "UPDATE_SLOT_ENFANT":
            state.slots = state.slots.filter(x => x.segment != SegmentEnum.Enfant)
            return Object.assign({}, state, {
                slots: [...state.slots, ...action.slots],
            })
        case "UPDATE_SLOT_MENAGE":
            state.slots = state.slots.filter(x => x.segment != SegmentEnum.Menage)
            return Object.assign({}, state, {
                slots: [...state.slots, ...action.slots],
            })
        case "UPDATE_SLOT_RESTAURATION":
            state.slots = state.slots.filter(x => x.segment != SegmentEnum.Restauration)
            return Object.assign({}, state, {
                slots: [...state.slots, ...action.slots],
            })
        case "REMOVE_SLOT":
            const resutl = state.slots.filter(s => !((s.segment == action.slot.segment) && (s.heure == action.slot.heure) && (s.jour == action.slot.jour)))
            return Object.assign({}, state, {
                slots: [...resutl],
            })
        case "CLEAR":
            return initialState
        default:
            return initialState
    }


}

const initialState = {
    slots: [],
    name: "",
    surname: "",
    email: "",
}


export interface contactForm {
    slots: Slot[]
    name: string
    surname: string
    email: string

}

export function updateSlot(slots: Slot[], segment: SegmentEnum) {
    switch (segment) {
        case SegmentEnum.Aide:
            return {
                type: 'UPDATE_SLOT_AIDE',
                slots
            }
        case SegmentEnum.Enfant:
            return {
                type: 'UPDATE_SLOT_ENFANT',
                slots
            }
        case SegmentEnum.Menage:
            return {
                type: 'UPDATE_SLOT_MENAGE',
                slots
            }
        case SegmentEnum.Restauration:
            return {
                type: 'UPDATE_SLOT_RESTAURATION',
                slots
            }
    }
}


export function removeOneSlot(slot: Slot) {
    return {
        type: 'REMOVE_SLOT',
        slot
    }
}
