import { merge } from "theme-ui";
import { tailwind } from "@theme-ui/presets";

const styledSlider = (bg = "#a5d5db") => ({
  "&::-ms-thumb": {
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    "appearance": " none",
    width: "25px",
    height: " 25px",
    background: "#a5d5db",
    cursor: "pointer",
  },
  "&::-moz-range-thumb": {
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    "appearance": " none",
    width: "25px",
    height: " 25px",
    background: "#a5d5db",
    cursor: "pointer",
  },
  "&::-webkit-slider-thumb": {
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    "appearance": " none",
    width: "25px",
    height: " 25px",
    background: bg,
    cursor: "pointer",
  }
})
const sharedButtonStyles = {
  border: `none`,
  color: `black`,
  cursor: `pointer`,
  fontFamily: `body`,
  fontWeight: `medium`,
  transition: `all 0.3s ease-in-out`,
  outline: `none`,
};

const sharedHeadingStyles = {
  color: `heading`,
};

export default merge(tailwind, {
  initialColorModeName: `light`,
  useCustomProperties: true,
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
      background: tailwind.colors.white,
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: "1px solid",
      borderColor: "muted",
    },
  },
  messages: {
    primary: {
      borderLeftColor: tailwind.colors.blue[2],
      backgroundColor: tailwind.colors.gray[2],
    },
  },
  forms: {
    // todo changer les nom des variant + changer SegmentEnum
    MENAGE: {
      color: 'black',
      lineHeight: "5px",
      maxHeight: "0.5em",
      ...styledSlider("#ADD4D9")
    },
    ENFANT: {
      color: 'black',
      lineHeight: "5px",
      maxHeight: "0.5em",
      ...styledSlider("#F197A9")
    },
    AIDE: {
      color: 'black',
      lineHeight: "5px",
      maxHeight: "0.5em",
      ...styledSlider("#F8BF9A")
    },
    RESTAURATION: {
      color: 'black',
      lineHeight: "5px",
      maxHeight: "0.5em",
      ...styledSlider("#BDDAA8")
    },
    RESTAURATIONGASTRO: {
      color: 'black',
      lineHeight: "5px",
      maxHeight: "0.5em",
      ...styledSlider("#BDDAA8")
    },
  },
  colors: {
    menage: ["#a5d5db", "#a5d5FF"],
    aide: ["#ffbd94", "#ffbdFF"],
    restauration: ["#b6dca4", "#b6dcFF"],
    enfant: ["#ff91a9", "#ff91FF"],
    yellow: "#F8E942",
    primary: "#F8E942",
    shadow: tailwind.colors.gray[4],
    secondary: "#fff3ea",
    background: tailwind.colors.gray[1],
    backgroundThemes: tailwind.colors.white,
    textMuted: `#667488`,
    text: tailwind.colors.gray[8],
    grayDark: tailwind.colors.gray[9],
    heading: tailwind.colors.gray[9],
    dark: tailwind.colors.gray[7],
    muted: tailwind.colors.gray[5],
    light: tailwind.colors.gray[3],
    starsCount: tailwind.colors.gray[7],
    model: {
      one: tailwind.colors.gray[2],
      two: tailwind.colors.gray[3],
      three: tailwind.colors.gray[4],
      four: tailwind.colors.gray[5],
    },
    modes: {
      dark: {
        primary: tailwind.colors.indigo[5],
        shadow: tailwind.colors.gray[8],
        background: tailwind.colors.gray[9],
        backgroundThemes: tailwind.colors.gray[8],
        textMuted: `#7a889c`,
        text: tailwind.colors.gray[3],
        grayDark: tailwind.colors.gray[1],
        heading: tailwind.colors.gray[2],
        dark: tailwind.colors.gray[5],
        light: tailwind.colors.gray[7],
        model: {
          one: tailwind.colors.gray[8],
          two: tailwind.colors.gray[7],
          three: tailwind.colors.gray[6],
          four: tailwind.colors.gray[5],
        },
      },
      strangerThings: {
        primary: `#E7251D`,
        background: `#000114`,
        shadow: `#3d1023`,
        textMuted: `#94a3b7`,
        backgroundThemes: `transparent`,
        text: `white`,
        dark: tailwind.colors.gray[4],
        heading: `#000114`,
        light: `#842626`,
        starsCount: tailwind.colors.gray[8],
        model: {
          one: `#000114`,
          two: `#000114`,
          three: `#04041b`,
          four: `#000`,
        },
      },
    },
  },
  fonts: {
    body: `"IBM Plex Sans", -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
    heading: `"IBM Plex Sans", -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
  },
  breakpoints: [`480px`, `768px`, `980px`, `1100px`],
  radii: {
    xl: `1rem`,
  },
  layout: {
    footer: {
      textAlign: `center`,
      display: `block`,
      color: `textMuted`,
      background: "pink",
      px: [2, 3],
      py: [4, 5],
      paddingTop: 0,
    },
    container: {
      maxWidth: `1200px`,
      padding: 4,
    },
  },
  styles: {
    root: {
      margin: 0,
      padding: 0,
      boxSizing: `border-box`,
      textRendering: `optimizeLegibility`,
      fontSize: `18px`,
      WebkitFontSmoothing: `antialiased`,
      MozOsxFontSmoothing: `grayscale`,
      color: `text`,
      backgroundColor: `background`,
      fontFamily: `body`,
      lineHeight: `body`,
      fontWeight: `body`,
    },
    p: {
      fontSize: ["0.7em", "0.75em", "0.9rem"],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      textAlign: "justify",
    },
    h1: {
      ...sharedHeadingStyles,
      fontSize: ["1.3em", "1.5em", "1.8em"],
      fontWeight: `body`,
      mt: 2,
    },
    h2: {
      ...sharedHeadingStyles,
      fontSize: ["0.99em", "1.13em", "1.35em"],
      mt: 2,
    },
    h3: {
      ...sharedHeadingStyles,
      fontSize: ["0.77em", "0.88em", "1.05em"],
      mt: 3,
    },
    h4: {
      ...sharedHeadingStyles,
      fontSize: ["0.7em", "0.75em", "0.9rem"],
    },
    h5: {
      ...sharedHeadingStyles,
      fontSize: ["0.66em", "0.62em", "0.75rem"],
    },
    h6: {
      ...sharedHeadingStyles,
      fontSize: ["0.6em", "0.50em", "0.60rem"],
      mb: 2,
    },
    em: {
      fontSize: "0.9em",
    },
    li: {
      fontSize: ["0.7em", "0.75em", "0.9rem"],
    },
    a: {
      color: "black",
      textDecoration: "underline",
    },
    table: {
      width: "100%"
    },
    th: {
      fontWeight: "normal",
    },
    td: {
      verticalAlign: "top",
      paddingRight: '0.5em',
    },
  },
  alerts: {
    primary: {
      color: 'background',
      bg:  tailwind.colors.red[8],
      fontSize:"0.5em",
      fontWeight: "normal",
    },
    muted: {
      color: 'text',
      bg: 'muted',
    },
  },
  buttons: {
    vertical: {
      flexDirection: "column",
      ...sharedHeadingStyles,
      fontSize: ["0.64em", "0.70em", "0.80rem"],
      mb: 2,
    },
    menage: {
      ...sharedButtonStyles,
      borderRadius: `full`,
      backgroundImage: (t) =>
        `linear-gradient(45deg, ${t.colors.menage[0]}, ${t.colors.menage[1]})`,
      fontSize: ["0.99em", "1.13em", "1.35em"],
      px: 4,
      py: `0.6rem`,
      boxShadow: (t) => `0px 10px 15px ${t.colors.shadow}`,
      "&:hover": {
        transform: `translateY(-2px)`,
        boxShadow: (t) => `0px 20px 25px ${t.colors.shadow}`,
      },
    },
    aide: {
      ...sharedButtonStyles,
      borderRadius: `full`,
      backgroundImage: (t) =>
        `linear-gradient(45deg, ${t.colors.aide[0]}, ${t.colors.aide[1]})`,
      fontSize: ["0.99em", "1.13em", "1.35em"],
      px: 4,
      py: `0.6rem`,
      boxShadow: (t) => `0px 10px 15px ${t.colors.shadow}`,
      "&:hover": {
        transform: `translateY(-2px)`,
        boxShadow: (t) => `0px 20px 25px ${t.colors.shadow}`,
      },
    },
    restauration: {
      ...sharedButtonStyles,
      borderRadius: `full`,
      backgroundImage: (t) =>
        `linear-gradient(45deg, ${t.colors.restauration[0]}, ${t.colors.restauration[1]})`,
      fontSize: ["0.99em", "1.13em", "1.35em"],
      px: 4,
      py: `0.6rem`,
      boxShadow: (t) => `0px 10px 15px ${t.colors.shadow}`,
      "&:hover": {
        transform: `translateY(-2px)`,
        boxShadow: (t) => `0px 20px 25px ${t.colors.shadow}`,
      },
    },
    enfant: {
      ...sharedButtonStyles,
      borderRadius: `full`,
      backgroundImage: (t) =>
        `linear-gradient(45deg, ${t.colors.enfant[0]}, ${t.colors.enfant[1]})`,
      fontSize: ["0.99em", "1.13em", "1.35em"],
      px: 4,
      py: `0.6rem`,
      boxShadow: (t) => `0px 10px 15px ${t.colors.shadow}`,
      "&:hover": {
        transform: `translateY(-2px)`,
        boxShadow: (t) => `0px 20px 25px ${t.colors.shadow}`,
      },
    },
    primary: {
      ...sharedButtonStyles,
      borderRadius: `full`,
      background: "#F8E942",
      fontSize: ["0.99em", "1.13em", "1.35em"],
      px: 4,
      py: `0.6rem`,
      boxShadow: (t) => `0px 10px 15px ${t.colors.shadow}`,
      "&:hover": {
        transform: `translateY(-2px)`,
        boxShadow: (t) => `0px 20px 25px ${t.colors.shadow}`,
      },
    },
    secondary: {
      ...sharedButtonStyles,
      borderRadius: `full`,
      background: "#FFF3EA",
      fontSize: ["0.77em", "0.88em", "1.05em"],
      px: 4,
      py: 1,
      boxShadow: `default`,
      "&:hover": {
        transform: `translateY(-1px)`,
      },
    },
    heroStars: {
      ...sharedButtonStyles,
      fontSize: ["0.99em", "1.13em", "1.35em"],
      boxShadow: `default`,
      borderRadius: `default`,
      "&:hover,&:focus": {
        transform: `translateY(-1px)`,
      },
    },
    outline: {
      ...sharedButtonStyles,
      backgroundColor: `transparent`,
      borderWidth: 2,
      borderColor: `white`,
      borderStyle: `solid`,
      borderRadius: `full`,
      textAlign: `center`,
      fontSize: 1,
      fontWeight: `semibold`,
      px: 4,
      py: `0.7rem`,
      "&:hover": {
        backgroundColor: `white`,
        color: `primary`,
      },
    },
    white: {
      ...sharedButtonStyles,
      borderRadius: `full`,
      fontSize: 1,
      fontWeight: `semibold`,
      px: 4,
      py: `0.75rem`,
      backgroundColor: `white`,
      color: `primary`,
      "&:hover": {
        transform: `translateY(-1px)`,
      },
    },
    transparent: {
      color: `inherit`,
      bg: `transparent`,
      transition: `all 0.3s ease-in-out`,
      border: `none`,
      "&:hover, &:focus": {
        color: `primary`,
        outline: `none`,
        boxShadow: `outline`,
        cursor: `pointer`,
      },
    },
  },
  inputs: {
    primary: {
      px: 3,
      py: `0.6rem`,
      fontSize: 1,
      borderRadius: `default`,
      boxShadow: `default`,
      border: `none`,
      outline: `none`,
      "&:focus": {
        boxShadow: `outline`,
      },
    },
  },
  gradients: {
    blue: {
      backgroundImage: (t) =>
        `linear-gradient(45deg, ${t.colors.indigo[3]}, ${t.colors.indigo[5]})`,
    },
    orange: {
      backgroundImage: (t) =>
        `linear-gradient(225deg, ${t.colors.orange[3]}, ${t.colors.orange[5]})`,
    },
    pink: {
      backgroundImage: (t) =>
        `linear-gradient(135deg, ${t.colors.pink[4]}, ${t.colors.pink[6]})`,
    },
    purple: {
      backgroundImage: (t) =>
        `linear-gradient(135deg, ${t.colors.purple[6]}, ${t.colors.purple[3]})`,
    },
    gray: {
      backgroundImage: (t) =>
        `linear-gradient(135deg, ${t.colors.gray[5]}, ${t.colors.gray[7]})`,
    },
    lightgray: {
      backgroundImage: (t) =>
        `linear-gradient(10deg, ${t.colors.gray[3]}, ${t.colors.gray[6]})`,
    },
    black: {
      backgroundImage: (t) =>
        `linear-gradient(135deg, ${t.colors.gray[7]}, ${t.colors.gray[9]})`,
    },
    red: {
      backgroundImage: (t) =>
        `linear-gradient(225deg, ${t.colors.red[6]}, ${t.colors.red[3]})`,
    },
    yellow: {
      backgroundImage: (t) =>
        `linear-gradient(180deg, ${t.colors.yellow[5]}, ${t.colors.yellow[6]})`,
    },
    green: {
      backgroundImage: (t) =>
        `linear-gradient(225deg, ${t.colors.green[3]}, ${t.colors.green[5]})`,
    },
    indigo: {
      backgroundImage: (t) =>
        `linear-gradient(72deg, ${t.colors.indigo[7]}, ${t.colors.indigo[5]})`,
    },
    secondary: {
      backgroundImage: (t) =>
        `linear-gradient(10deg, ${t.colors.orange[2]}, ${t.colors.orange[4]})`,
    },
    teal: {
      backgroundImage: (t) =>
        `linear-gradient(72deg, ${t.colors.teal[7]}, ${t.colors.teal[5]})`,
    },
    darkIndigo: {
      backgroundImage: (t) =>
        `linear-gradient(135deg, ${t.colors.indigo[8]}, ${t.colors.indigo[6]})`,
    },
    strangerThings: {
      backgroundImage: `radial-gradient(#BA230D, #520506)`,
    },
  },
});
